import React from "react"
import {graphql} from "gatsby"

import ImgPage from "../images/headers/Logos_Ale_Preowned.svg"
import Layout from "../components/layout"
import SEO from "../components/seo"
import style from "./preowned.module.scss"
import Header from "../components/header";
import Img from "gatsby-image"

// import LogoJetCraft from "../images/preowned/logo_jetcraft.png"
import Foto1 from "../images/preowned/foto1.jpg"

import Logo1 from "../images/preowned/icon_money.png"
import Logo2 from "../images/preowned/icon_document.png"
import Logo3 from "../images/preowned/icon_lupa.png"
import Logo4 from "../images/preowned/icon_engrane.png"
import Logo5 from "../images/preowned/icon_check.png"
import Logo6 from "../images/preowned/icon_conect.png"

import Logo7 from "../images/preowned/logo_asa.png"
import Logo8 from "../images/preowned/logo_iada.png"
import Logo9 from "../images/preowned/logo_jssi.png"
import PreownedDoc from "../docs/Preowned.pdf"
// import PreownedDocCat from "../docs/Catalogo_Master.pdf"
import JetCraft from '../images/icons/jetcraft.png'
import Preowned from '../images/icons/preowned.png'
import Form from "../components/form";

export default ({data}) => {
    const extraInfo =
        [
            {name: '', mail: 'info@ale.mx', phone: '554 202 0920'},
        ];
    return (
        <Layout extraInfo={extraInfo}>

            <Header LogoPage={ImgPage}/>
            <SEO title="Preowned"/>

            <div className={style.preCover}>
                <Img fluid={data.portada.childImageSharp.fluid}/>
                <a href={PreownedDoc} download={true}>Descargue nuestro folleto</a>
            </div>


            <div className={style.preInfo}>
                <h1>Ale Preowned</h1>
                <p>Ale Preowned es el área de venta de aviones seminuevos, reconocida internacionalmente y donde
                    construimos
                    relaciones profesionales a largo plazo donde ofrecemos soporte y asesoría durante todo el proceso de
                    compra venta de su aeronave. Contando con garantías únicas y ofreciendo nuestro expertise como los
                    único
                    valuadores certificados en México.</p>
                <Form align="bottom" options={['PreOwned: Aircraft Brokerage']}/>

            </div>

            <div className={style.jetcraft}>
                <div className={style.left}>
                    <div className={style.logos}>
                        <img src={Preowned} alt={'Preowned'}/>
                        <span></span>
                        <img src={JetCraft} alt={'Jetcraft'}/>
                    </div>
                    <a href="http://jetcraft.com/" target="_blank" rel="noopener noreferrer">Conozca Más</a>
                </div>
                <div className={style.right}>
                    <p><b>Representante Autorizado</b></p>
                    <p>Dos líderes de la aviación ejecutiva han formado una alianza estratégica en México, Centroamérica
                        y
                        el Caribe con uno de los inventarios más amplios de la región, generando una atractiva oferta
                        para
                        clientes que buscan comprar, vender, cambiar su aeronave o consultoría. Esta alianza añade el
                        alcance global de Jetcraft al conocimiento local de Ale PreOwned, conjugando más de un siglo de
                        experiencia combinada y construyendo una alianza que pondrá los cielos a su alcance.</p>
                </div>
            </div>

            <div className={style.preDo}>

                <div className={style.title}>
                    <p>Lo que hacemos</p>
                </div>

                <div className={style.infoBlock}>
                    <div className={style.info}>
                        <ul>
                            <li><img alt="Preowned" src={Logo1}/><p>Adquisición, venta e intercambio de aeronaves</p>
                            </li>
                            <li><img alt="Preowned" src={Logo2}/><p>Estructuración de transacciones</p></li>
                            <li><img alt="Preowned" src={Logo3}/><p>Valuación de condiciones y valor de aeronaves</p>
                            </li>
                            <li><img alt="Preowned" src={Logo4}/><p>Inteligencia en la industria e investigación de
                                mercados</p></li>
                            <li><img alt="Preowned" src={Logo5}/><p>Soluciones alternativas de adquisición y estrategias
                                de posesión</p></li>
                            <li><img alt="Preowned" src={Logo6}/><p>Planeación de flotillas</p></li>
                        </ul>
                    </div>
                    <img src={Foto1} alt="Lorem"/>
                </div>
                <div className={style.title}>
                    <p>Presencia en Centroamérica y el Caribe</p>
                    <span> </span>
                </div>
                <Img fluid={data.mapa.childImageSharp.fluid}/>

            </div>

            <div className={style.preCertificates}>
                <h2>Nuestras Certificaciones</h2>
                <div className={style.item}>
                    <img src={Logo7} alt="Certificates"/>
                    <p>La American Society of Appraisers es la asociación comercial multidisciplinaria, de membresía voluntaria y más grande que representa y promueve a sus tasadores miembros. La sociedad fue fundada en 1936 y es uno de los ocho grupos de evaluación de asociaciones comerciales que fundaron The Appraisal Foundation.</p>
                </div>
                <div className={style.item}>
                    <img src={Logo9} alt="Certificates"/>
                    <p>Jet Support Services, Inc. es el mayor proveedor de trabajos de mantenimiento para aviones comerciales, la compañía ofrece programas de mantenimiento de costos por hora, para motores de aviones corporativos y privados, APU y fuselajes.</p>
                </div>
                <div className={style.item}>
                    <img src={Logo8} alt="Certificates"/>
                    <p>La International Aircraft Dealers Association es la fuerza colectiva que influye y da forma a la industria de transacciones de aeronaves, desarrollando estándares para transacciones de aeronaves comerciales eficientes, efectivas y éticas. Con valores de profesionales altamente calificados en transacciones de aeronaves para su éxito.</p>
                </div>
            </div>

            <div className={style.preDown}>
                <Img fluid={data.portadaAbajo.childImageSharp.fluid}/>
                <a href={'https://aerolineasejecutivas.wixsite.com/alepreowned'} target={'_blank'} rel="noreferrer">Conozca nuestro catálogo</a>
            </div>

        </Layout>
    )
}

export const query = graphql`
    query ImagesPreowned {
    portada: file(relativePath: {eq: "preowned/portada.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    portadaAbajo: file(relativePath: {eq: "preowned/foto_abajo.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    mapa: file(relativePath: {eq: "preowned/mapa.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}`





